// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---gatsby-theme-nurofen-src-templates-campaign-page-plain-campaign-page-plain-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/CampaignPagePlain/CampaignPagePlain.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-campaign-page-plain-campaign-page-plain-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-contact-us-page-contact-us-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/ContactUsPage/ContactUsPage.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-contact-us-page-contact-us-page-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-error-404-page-error-404-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/Error404Page/Error404Page.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-error-404-page-error-404-page-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-lds-page-lds-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/LdsPage/LdsPage.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-lds-page-lds-page-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-sitemap-page-sitemap-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/SitemapPage/SitemapPage.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-sitemap-page-sitemap-page-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-why-nurofen-page-why-nurofen-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/WhyNurofenPage/WhyNurofenPage.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-why-nurofen-page-why-nurofen-page-tsx" */)
}

